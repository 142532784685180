import { styled } from "styled-components";

export const ScheduleDetailContainer = styled.div`
  text-align: left;
  width: 100%;
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.5;
  }
`;
